
<div class="menu-kaizen_line" *ngIf="!noMostrar">
    <div nz-row class="top-menu">
        <!--Icono menú versión Mobile-->
        <div nz-col nzSpan="3" class="menu-mobile">
            <a (click)="openMobile()"><img [src]="menumobile" alt="icono menu" class="icon-menu_mobile"/></a>
        </div>
        <!-- Logo versión mobile --> 
        <div nz-col nzSpan="18" class="logomobile">
            <a routerLink="/" >
                <img src="{{logo}}" alt="Logo Kaizen Motors" width="147" height="43"/>
            </a>
        </div>
        <div nz-col nzSpan="3" class="btnOptions">
            <div *ngIf="!logueado" class="icon_menuLogin" >
                
            </div>
            <nz-badge [nzCount]="cantidadCart" *ngIf="logueado">
                <a routerLink="/carrito" class="kaizen-cart_mobile">
                    <span class="icon-cart"></span>
                </a>
            </nz-badge>
        </div>
    </div>
    <div nz-row class="search-input">
        <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
              <input type="text" nz-input placeholder="Qué estas buscando?" [(ngModel)]="searchDesktop" (keyup)="onKey($event)"/>
          </nz-input-group>
          <ng-template #suffixIconButton>
              <button nz-button nzSearch (click)="linkSearch()" style="background: red;color: #fff;" class="btn-search back-kaizen"><i nz-icon nzType="search" nzTheme="outline"></i></button>
          </ng-template>
    </div>
    <div class="submenu-scroll">
        <div class="sub-menu_links">
            <a routerLink="/repuesto/asakashi" class="sub-menu_links__ref">Repuestos</a>
            <nz-divider nzType="vertical"></nz-divider>
            <a href="/servicio-tecnico" class="sub-menu_links__ref">Agendar cita</a>
            <nz-divider nzType="vertical"></nz-divider>
            <a routerLink="/chaperio" class="sub-menu_links__ref">Chaperio</a>
            <nz-divider nzType="vertical"></nz-divider>
            <!--<a routerLink="/ofertas/pintado-express" class="sub-menu_links__ref">Pintado Express</a>
            <nz-divider nzType="vertical"></nz-divider>
            <a routerLink="/ofertas/pintado-express" class="sub-menu_links__ref">Pack de Mantenimiento</a>-->
            <a href="/liquidacion/repuestos" class="sub-menu_links__ref"><img src="../../../assets/images/iconos/price-tag2.svg" width="20" height="20" alt="icono cyberweek" class="mini-icon"/> Liquidación de repuestos</a>
            <nz-divider nzType="vertical"></nz-divider>
            <a routerLink="/contacto" class="sub-menu_links__ref">Contacto</a>
            <!--<nz-divider nzType="vertical"></nz-divider>
            <a href="/blackweek/oferta-en-retrovisores" class="sub-menu_links__ref"><img src="../../../assets/images/iconos/price-tag2.svg" width="20" height="20" alt="icono cyberweek" class="mini-icon"/> Ofertas</a>-->
        </div>
    </div>
</div>



<nz-drawer [nzClosable]="false" [nzVisible]="visibleMenuMobile" [nzPlacement]="'left'" class="kaizen-menu_mobilePrincipal" 
nzTitle="<span class='icon-kaizen-micuenta-mobile iconuser-mobile'></span> {{nombreUsuario}}" (nzOnClose)="closeMobile()">
    <ng-container *nzDrawerContent>
        <ul nz-menu nzMode="inline" class="kaizen-menu_mobile menumobile-personal" *ngIf="logueado" >
            <li nz-menu-item>
                <a routerLink="/usuario/perfil">Cuenta</a>
            </li>
            <li nz-menu-item>
                <a routerLink="/carrito">Tu Carrito</a>
            </li>
            <li nz-menu-item>
                <a>Tu Historial</a>
            </li>
        </ul>
        <h3 class="titlemoblile-marca" *ngIf="logueado">KAIZEN MOTORS</h3>
        <ul nz-menu nzMode="inline" class="kaizen-menu_mobile">
            <li nz-menu-item >
                <a routerLink="/" routerLinkActive="active">Inicio</a>
            </li>
            <li nz-submenu>
                <span title><span class="menu-mobile_primerNivel">Repuestos</span></span>
                <ul class="kaizen-submenu_mobile">
                    <li nz-menu-item *ngFor="let marcasFiltros of marcas; let i = index">
                        <a href="{{getUrl(marcas[i]['nombre_marca_repuesto'])}}">{{marcas[i]['nombre_marca_repuesto']}}</a>
                    </li>
                </ul>
            </li>  
              <li nz-menu-item >
                  <a href="/servicio-tecnico">Servicio Técnico</a>
              </li>
              <li nz-menu-item >
                  <a href="/chaperio">Chaperio</a>
              </li>
            <li nz-menu-item >
                <a routerLink="/bolsa-trabajo" routerLinkActive="active">Bolsa de trabajo</a>
            </li>
            <li nz-menu-item >
                <a routerLink="/contacto" routerLinkActive="active">Contacto</a>
            </li>
        </ul>
        <h3 class="title-tienda-virtual" *ngIf="!logueado">TIENDA VIRTUAL</h3>
        <ul nz-menu nzMode="inline" class="kaizen-menu_mobile" *ngIf="!logueado">
            <li nz-menu-item >
                <a routerLink="/login" routerLinkActive="active">Ingresar</a>
            </li>
            <li nz-menu-item >
              <a routerLink="usuario/registro" routerLinkActive="active">Registrarme</a>
          </li>
        </ul>
      
        <a (click)="logout()" class="linkmobile-out" *ngIf="logueado"><i nz-icon nzType="logout" nzTheme="outline"></i> Salir</a>
    </ng-container>

</nz-drawer>